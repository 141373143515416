.inputBox {
  width: 100%;
  height: 4rem;
  padding: 0.6rem 0.6rem;
  color: rgba(128, 128, 128, 0.6);
  border-radius: 6px;
  box-shadow: none;
  border: 1px solid #cbd6e2;
  margin: 0.25rem auto;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 992px) {
    height: 3.6rem;
  }

  @media (max-width: 768px) {
    height: 3.3rem;
  }
  @media (max-width: 576px) {
    height: 3.1rem;
  }
  @media (max-width: 472px) {
    height: 3rem;
  }

  > .inn {
    width: 100%;
    height: 100%;

    > input {
      text-align: left;
      outline: none;
      width: 100%;
      border: none;
      box-shadow: none;
      font-size: 1.2rem;
      background: transparent;

      @media (max-width: 1200px) {
        font-size: 1rem;
      }
      @media (max-width: 992px) {
        font-size: 0.85rem;
      }
      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
      @media (max-width: 576px) {
        font-size: 0.7rem;
      }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    > .label {
      transition: all 0.1s ease-out;
      font-size: 0.8rem;
    }
  }

  > .norm {
    > .label {
      display: flex;
      align-items: center;
      height: 100%;
    }

    > input {
      display: none;
    }
  }

  > .icon {
    height: 82.5%;
    width: 2rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.35rem !important;
    > img {
      display: block;
    }
  }

  > .act {
    border-color: #00aeef;
    > .label {
      font-size: 0.7rem;
      align-items: flex-start;
      height: auto;

      @media (max-width: 768px) {
        margin-top: -0.15rem;
        font-size: 0.5rem;
      }
    }
    > input {
      display: block;
    }
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #00aeef;
    color: #99acc2;
    box-shadow: 0px 0px 9px #00afef5d;
    outline: none;

    > .inn {
      > input {
        display: block;
      }

      > .label {
        font-size: 0.7rem;
        align-items: flex-start;
        height: auto;

        @media (max-width: 768px) {
          margin-top: -0.15rem;
          font-size: 0.5rem;
        }
      }
    }
  }
}

.actBox {
  border-color: #00aeef;
  color: #99acc2;
  outline: none;
  > .inn {
    > input {
      display: block;
      height: 100%;
    }

    > .label {
      font-size: 0.7rem;
      margin-top: -1rem;
      align-items: flex-start;
      height: auto;
      background-color: #fff;
      padding: 2px 6px;
      width: fit-content;

      @media (max-width: 768px) {
        margin-top: -0.15rem;
        font-size: 0.5rem;
      }
    }
  }
}
