.help-sidenavbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 480px;
  height: 100vh;
  border: 1px solid #cbd6e2;
  background-color: #fff;
  border-top: none;
  box-shadow: 3px 0px 8px #425b7681;
  z-index: 1000;

  > .background {
    background-image: url("./images/background.png");
    width: 100%;
    height: 180px;
    box-shadow: inset 0px 3px 6px #425b7638;
  }
}
