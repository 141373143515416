.signIn {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: hidden !important;
  position: relative;

  .left {
    align-self: center;
    width: 37.5rem;
    max-width: 95%;
    height: 100%;
    background: white;

    @media (max-width: 1600px) {
      width: 32.5rem;
    }
    @media (max-width: 1200px) {
      width: 30rem;
    }
    @media (max-width: 992px) {
      padding-top: 0;
      width: 27.5rem;
    }

    .iconContainer {
      margin: 0rem auto;
      margin-top: 1.5rem;

      > .icon {
        width: 15rem;
        height: 4.8rem;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        > img {
          height: auto;
          width: 90%;
        }
      }
    }

    > .form {
      width: 80%;
      max-width: 90%;
      margin: 0px auto;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #33475b;

      > .iconContainer {
        margin: 1rem auto;
        > .icon {
          height: 2.5rem;
          margin: auto;
          background-size: cover;
        }
      }

      > h1 {
        font-size: 2.75rem;
        color: #33475b;

        @media (max-width: 1200px) {
          font-size: 2.25rem;
        }
        @media (max-width: 992px) {
          font-size: 1.75rem;
        }
        @media (max-width: 678px) {
          font-size: 1.5rem;
        }
      }

      > p {
        margin-top: 0.65rem;

        @media (max-width: 678px) {
          margin-top: 0.5rem;
        }
      }

      .formSpace {
        margin-top: 1.5rem;

        > p {
          text-align: right;
          margin: 0.6rem auto 1.2rem auto;
          color: #00bcd4;
          cursor: pointer;

          > a {
            color: #00bcd4;
            text-decoration: none;
            &:hover {
              opacity: 0.8;
              text-decoration: underline;
            }
          }
        }
        .label {
          color: rgba(128, 128, 128, 0.6);
        }
        > .button {
          width: 100%;
          height: 3.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 500;
          cursor: pointer;
          background: #33475B;

          &:hover {
            opacity: 0.8;
          }

          @media (max-width: 678px) {
            height: 2.75rem;
          }

          > span {
            display: inline-block;
            position: relative;
            margin-left: 1rem;
          }
        }
      }

       .google-btn {
        width: 100%;
        min-width: min-content;
        background-color: #1a73e8;
        border: none;
        color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
        transition: background-color 0.218s, border-color 0.218s;
        background-image: none;
        border: 1px solid #dadce0;
        cursor: pointer;
        font-family: "Google Sans", arial, sans-serif;
        font-size: 14px;
        height: 46px;
        letter-spacing: 0.25px;
        outline: none;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;

        .google-btn-sps {
          transition: background-color 0.218s;
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }

        .google-btn-spa {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: nowrap;
          height: 100%;
          position: relative;
          width: 100%;
        }

        .google-btn-spb {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          height: 36px;
          margin-left: -10px;
          margin-right: 12px;
          min-width: 36px;
          width: 36px;
        }

        .google-btn-img {
          margin: 0;
          padding: 0;
          height: 18px;
          min-width: 18px;
          width: 18px;
        }

        .google-btn-txt {
          -webkit-flex-grow: 1;
          flex-grow: 1;
          font-family: "Google Sans", arial, sans-serif;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: top;
        }
      }

      > .noAcc {
        margin-top: 2rem;

        > a {
          text-decoration: none;
          color: #00bcd4;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
            text-decoration: underline;
          }
        }

        @media (max-width: 1200px) {
          margin-top: 3rem;
        }
        @media (max-width: 992px) {
          margin-top: 2.5rem;
        }
        @media (max-width: 678px) {
          margin-top: 2rem;
        }
      }
    }
  }
  > .right {
    width: 70%;
    background: #eaf0f6;
    img {
      height: 100% !important;
      width: 100% !important;
    }
  }
  > .footer {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    left: 0;
  }
}
