.subscription {
  width: 100%;
  display: flex;

  .subscription-title {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    h5 {
      font-size: 1.8rem;
      font-weight: 400;
      margin: 0.8rem 0;
    }
  }
  .subscription-main {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .subscription-content {
      width: 100%;
      margin: 0 auto;
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      h2 {
        margin: 0;
        font-weight: 600;
        color: #334760;
      }

      p {
        font-size: 14px;
        margin: 0;
        color: #516f90;
        margin-top: 10px;
      }
      .text-content-big {
        width: auto;
      }
      .image {
        width: 150px;
        height: auto;
        overflow: hidden;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .session-ip {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 2rem;
        > h5 {
          font-weight: 400;
        }
        .session-ip-list {
          width: 90%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
        }
      }

      .product-box {
        width: 100%;
        padding-top: 12px;
        display: flex;
        flex-direction: column;

        .product-name {
          .product-status {
            width: fit-content;
            margin: 0 auto;
          }
        }
        .product-details {
          width: 95%;
          height: 150px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .product-plan {
            display: flex;
            align-items: center;
            > p {
              margin: 0;
              margin-left: 1rem;
            }
          }
        }
        .product-action {
          cursor: pointer;
          color: #1389fd;
          font-weight: 600;
        }
        .product-subtext {
          width: 100%;
          text-align: center;
          span {
            font-weight: 500;
            color: #546878;
            padding: 0 5px;
          }
        }
      }
    }
  }
}
