.AppBar{
    box-sizing: border-box;
    height: 8vh;
    width: 100%;
    position: relative;
    padding: 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cbd6e2;
    background-color: #fff;
}

.ToolBar {
    width: 100%;
    display: flex;
    align-items: center;
}

.space-between {
    justify-content: space-between;
}
.space-around {
    justify-content: space-around;
}
.space-evenly {
    justify-content: space-evenly;
}
