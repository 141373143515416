.aui-center-form-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  position: relative;
  .aui-center-form {
    max-width: 460px;
    display: flex;
    flex-direction: column;
  }
}

.aui-awesome-logo {
  margin: 3.5rem 0;

  @media (max-width: 1300px) {
    margin: 2.5rem 0;
  }
  @media (max-width: 900px) {
    margin: 2rem 0;
  }
}

.side-image {
  position: absolute;
  left: 0;
  top: 20%;
  width: 38%;
  
  img {
    width: 90%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1200px) {
    display: none;
  }

}

.img-emoji {
  font-size: 2rem;
}

.flex-it {
  display: flex;
}

.validation {
  padding: 6px;
}
