.Billing-details-page {
  width: 100%;
  padding: 1rem 26px;
  display: flex;
  justify-content: space-between;

  .billing-form,
  .billing-cards {
    width: 50%;
    padding: 24px;
  }

  .billing-form-fields {
    max-width: 380px;

    .billing-input {
      margin-bottom: 12px;
    }
  }

  .billing-button {
    margin-top: 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    width: 380px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    background-color: #6A78D1;
    transition: 300ms ease-in-out;

    span {
      color: #fff;
    }

    &:hover {
      border: 1px solid #092f42;
      background-color: #6171d4;
    }
  }

  .billing-cards {
    .billing-cards-content {
      cursor: pointer;
      .billing-card-btn {
        cursor: pointer;
        width: fit-content;
        padding: 5px 12px;
        border-radius: 5px;
        background-color: #eaf0f6;
        border: 1px solid #092f42;

        &:hover {
          background-color: #092f42;
          color: #fff;
        }
      }

      .billing-bank-card {
        margin-top: 1.2rem;
        position: relative;
        width: 360px;
        height: 220px;
        padding: 1rem;
        border-radius: 10px;
        border: 1px solid #00A4BD;


        .card-main-content {
          z-index: 10;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .card-num {
            display: flex;
            margin-top: 1.2rem;
            font-size: 1.1rem;
            font-weight: 500;
            .card-icon {
              width: 30px;
              height: 30px;
              margin-right: 12px;
              background-color: #092f42;
            }
          }

          .card-details {
            margin-top: 0.7rem;

            .card-details-label {
              font-size: 1.05rem;
              font-weight: 600;
            }
          }
        }

        .card-active-dot,
        .card-inactive-dot{
        position: absolute;
        top: 8px;
        left: 8px;
        width: 22px;
        border-radius: 50%;
        height: 22px; 
      }

        .card-active-dot {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #00A4BD;
          > div {
            border-radius: inherit;
            height: 14px;
            width: 14px;
            background-color: #00A4BD;  
          }
        }
        .card-inactive-dot {
            border: 1px solid #CBD6E2;
        } 

        .update-card-btn {
          display: none;
          position: absolute;
          transition: all 500ms ease-in;
          bottom: 0;
          right: 0;
          padding: 6px 12px;
          border: 1px solid #00A4BD;
          border-radius: 10px;
          border-right: 0px;
          border-bottom: 0px;
          cursor: pointer;
          border-bottom-right-radius: 0px;
        }

        &:hover{
          .update-card-btn {
            display: block;
          }
        }
      }

      .card-active {
        background-color: #E5F5F8;
        box-shadow: 0px 1px 10px #11bdd8b0;
      }
    }
  }
}
