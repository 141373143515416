.tabs {
    width: 100%;
    height: 100%;

    .tab-nav {
        display: flex;
        align-items: center;
    
        .tab-rest {
            align-self: flex-end;
            flex: 1;
            margin-bottom: 0px;
            border-bottom: 1px solid #CBD6E2;
        }
    }
    .tab-content {
        border: 1px solid #CBD6E2;
        border-top: 0px;
        padding: 10px;
    }
    .noPadding {
        padding: 0px;
    }
    .noBorder {
        border: none;
    }
}