.profile {
  width: 100%;
  display: flex;

  .profile-sidebar {
    width: 16%;
    height: 100vh;
    overflow-y: auto;
    margin-top: -1rem;
    margin-left: -0.98rem;
    border-right: 1px solid #e4eaf0;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
      border-radius: 20px;
    }
    .sidebar-menu {
      border-left: 5px solid transparent;
      cursor: pointer;
      &:hover {
        background-color: #eaf0f6;
        border-left: 5px solid #1389fd;
      }
    }
    .selected {
      background-color: #eaf0f6;
      border-left: 5px solid #1389fd;
    }

    @media (max-width: 1300px) {
      width: 20%;
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .profile-main {
    width: 84%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1300px) {
      width: 80%;
    }

    .profile-intro {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      .profile-user {
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }

      .profile-user-details {
        width: fit-content;
        display: flex;
        margin: 0 auto;
        justify-content: space-evenly;
        > div {
          padding-right: 8px;
          margin-left: 8px;
          border-right: 1px solid #232525;
        }
      }

      > h2 {
        font-size: 2rem;
        color: #334760;
        margin: 0;
        margin: 1rem 0;
      }

      > p {
        font-size: 14px;
        color: #516f90;
      }
    }

    .profile-content {
      width: 90%;
      margin: 0 auto;
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      .edit-icon {
        width: 100%;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        font-size: 1rem;
        cursor: pointer;
        span {
          margin-right: 12px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .edit-buttons {
        display: flex;
        justify-content: flex-end;
      }

      h2 {
        margin: 0;
        font-weight: 600;
        color: #334760;
      }

     > p {
        font-size: 14px;
        margin: 0;
        color: #516f90;
        margin-top: 10px;
      }
      .text-content-big {
        width: auto;
      }
      .image {
        width: 225px;
        height: auto;
        overflow: hidden;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .accounts {
        display: flex;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    .users-access-apps {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-start;
      gap: 12px;

      .awesomsuit-apps {
        cursor: pointer;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.849);
        box-shadow: 0px 1px 4px #d3d3d3ad;

        &:hover {
          box-shadow: 0px 4px 6px #d3d3d3d0;
        }
      }
    }

    .profile-security {
      background-color: #fff;
      width: 840px;
      margin: 0 auto;
      border: 0.5px solid #cbd6e2;
      border-radius: 5px;
      padding: 1.5rem;
      .disable-account {
        box-shadow: 0px 1px 4px #d3d3d3ad;
        width: 450px;
        padding: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .disable-btn-base {
          margin-left: 12px;
          cursor: pointer;
          padding: 8px 14px;
          border-radius: 5px;
          color: #fff;
          background-color: #dc461f;
        }
      }

      .profile-change-password {
        box-shadow: 0px 1px 4px #d3d3d3ad;
        width: 450px;
        margin-top: 1rem;
        padding: 12px;

        .security-save-btn {
          width: 400px;
          text-align: center;
          margin: 0 auto;
          margin-top: 12px;
          cursor: pointer;
          padding: 10px 14px;
          border-radius: 5px;
          color: #fff;
          background-color: #7c98b6;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .profile-contacts {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      border-bottom: 1px solid #cbd6f2;

      .contacts-avatar {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        > p {
          margin-top: 0;
        }
      }
    }
  }
}
