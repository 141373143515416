.forgot-pass-title {
  text-align: center;
}

.otp-form {
  .enter-otp-cut-input {
    width: 65%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-radius: 6px;

    .otp-input-wrapper-active {
      position: relative;
    }
    .otp-input-wrapper-active::after {
      content: " ";
      width: 10px;
      height: 10px;
      z-index: -1;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #6e91b4;
    }

    .otp-input {
      width: 50px;
      height: 60px;
      border: none;
      background-color: #eaf0f6;
      border: 1px solid #cbd6e2;
      background-color: transparent;
      outline: none;
      border-radius: 4px;
      position: relative;
      font-size: 2.2rem;
      margin: 0 2px;
      text-align: center;
    }
  }
  .otp-verify-btn {
    margin: 0 auto;
    margin-top: 2rem;
    display: block;
    background-color: #6a78d1;
    padding: 12px 2rem;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;

    > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.alerts {
  position: absolute;
  bottom: 2%;
  right: 5%;
  transition: all ease-in 200ms;
  z-index: 200;
}
.alertsOpen {
  display: block;
  transform: translateX(0px);
}
.alertsClose {
  display: block;
  transform: translateX(130%);
}

.otp-not-received {
  text-align: center;
  font-size: 1rem;
  > span {
    color: #1592e6;
    cursor: pointer;
  }
}
