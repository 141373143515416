.table {
    width: 100%;
    // padding: 8px;
    border-radius: 5px;
    font-size: 1rem;
    border-collapse: collapse;
    >tbody:last-child {
        border-bottom: none;
    }
}

.border {
    border: 1px solid #DFE3EB;
}

