.widget-bottom {
    width: 100%;
    border-top: 1px solid #CBD6E2;
    padding: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: auto;
    justify-self: flex-end;
    .actions {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 4px;
        font-size: 14px;
        color: #00A4BD; 
        cursor: pointer;
        font-weight: 600;
    }
}
