.resorce-center-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 255, 255);
  border: 0.5px solid #cbd6e2;
  border-radius: 6px;
  box-shadow: 2px 3px 6px #425b762a;
  padding: 15px;
  margin-bottom: 9px;
  width: 96%;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  &:hover {
    box-shadow: 2px 3px 6px #425b7652;
  }

  > div {
    > h6 {
      font-size: 22px;
      font-weight: 600;
      color: #33475b;
      margin-top: 0;
      margin-bottom: 1px;
    }

    > p {
      font-size: 13px;
      color: #7c98b6;
      margin: 0;
    }
  }

  > img {
    width: 20px;
    height: 20px;
  }
}
