.main-widget{
    border: .5px solid #cbd6e2;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #fff;
    //box-shadow: #374a5d34 0px 1px 5px 0px;
}

.gutter-right {
    margin-right: 1rem;
    background: #fff;
}
.gutter-left {
    margin-left: 1rem;
    background: #fff;
}
.gutter-top {
    margin-top: 1rem;
}
.gutter-bottom {
    margin-bottom: 1rem;
}
