.resource-center-container {
  margin-top: -5rem;
  padding: 0px 13px;

  > p {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 7px;
    margin-left: 20px;
  }
}
