.signUp {
  border: 2px solid #cbd6e2;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-size: auto;
  background-attachment: fixed;
  border: 1px solid #cbd6e2;
  background-image: url("../../assets/LoginAndSignUpIcon/background.png");
  background-repeat: no-repeat;
  background-position: center;

  .oAuto-email {
    text-align: center;
    margin: 1rem 0;
    font-size: 1rem;
  }

  .error-message {
    padding: 12px;
    border-radius: 6px;
    background-color: rgb(255, 87, 87);
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;
  }

  .login-btn {
    margin-right: 2rem;
    margin-left: 1rem;
    padding: 4px 8px;
    border: 1px solid #D6DFE8;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    color: #00BDD9;
    text-decoration: none;
    transition: 100ms;

    &:hover {
      color: #fff;
      background: #00BDD9;
      border: 1px solid #00BDD9;
    }
  }

  > .try {
    color: #33475b;
    margin: 2rem auto;
    font-size: 2rem;

    @media (max-width: 1200px) {
      margin: 1.5rem auto;
      font-size: 1.75rem;
    }
    @media (max-width: 1200px) {
      margin: 1.5rem auto;
      font-size: 1.5rem;
    }
    @media (max-width: 992px) {
      margin: 1.5rem auto;
      font-size: 1.3rem;
    }
    @media (max-width: 768px) {
      margin: 1.5rem auto;
      font-size: 1.2rem;
    }
  }

  .oauth-login {
    margin: 0 auto;
    width: 33rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: #33475B;
    color: #fff;
    margin-top: 1rem;
  }

  .formBox {
    width: 33rem;
    max-width: 95%;
    margin: 2rem auto;
    box-shadow: 0px 0px 11px #cbd6e2;
    border: 1px solid #cbd6e2;
    border-radius: 0.5rem;
    padding: 2rem;
    background-color: white;

    @media (max-width: 1200px) {
      width: 30rem;
      padding: 1.5rem;
    }
    @media (max-width: 768px) {
      width: 27.5rem;
      padding: 1.1rem;
      margin: 1.5rem auto;
    }

    .green-clr {
      text-decoration: underline;
    }

    .iconContainer {
      width: 90%;
      margin: 0 auto;

      > .icon {
        height: 5rem;
        margin: 0 auto;
        background: #fff;
        background-size: cover;

        > img {
          width: 100%;
          height: auto;
        }
      }
    }

    > .formSpace {
       .google-btn {
        width: 100%;
        min-width: min-content;
        background-color: #1a73e8;
        border: none;
        color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
        transition: background-color 0.218s, border-color 0.218s;
        background-image: none;
        border: 1px solid #dadce0;
        cursor: pointer;
        font-family: "Google Sans", arial, sans-serif;
        font-size: 14px;
        height: 46px;
        letter-spacing: 0.25px;
        outline: none;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;

        .google-btn-sps {
          transition: background-color 0.218s;
          bottom: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }

        .google-btn-spa {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: nowrap;
          height: 100%;
          position: relative;
          width: 100%;
        }

        .google-btn-spb {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          height: 36px;
          margin-left: -10px;
          margin-right: 12px;
          min-width: 36px;
          width: 36px;
        }

        .google-btn-img {
          margin: 0;
          padding: 0;
          height: 18px;
          min-width: 18px;
          width: 18px;
        }

        .google-btn-txt {
          -webkit-flex-grow: 1;
          flex-grow: 1;
          font-family: "Google Sans", arial, sans-serif;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: top;
        }
      }

      > .hrDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(128, 128, 128, 0.75);

        > hr {
          border: 1.5px solid #dde5ec;
          width: 43.5%;
        }
        @media (max-width: 678px) {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
      .actBox {
        border-color: #00bcd4;
        color: #33475b;
        outline: none;
        .inn {
          input {
            display: block;
          }

          .label {
            font-size: 0.6rem;
            align-items: flex-start;
            height: auto;

            @media (max-width: 678px) {
              margin-top: -0.15rem;
              font-size: 0.5rem;
            }
          }
        }
      }
      .name {
        display: flex;

        > .inputBox {
          &:first-child {
            margin-right: 0.25rem;
          }
        }
      }

      > .checks {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        transform-origin: top;
        padding: 1rem 1rem 0 1rem;
        animation: incSize 0.5s ease;
        margin-bottom: 3rem;

        @media (max-width: 1200px) {
          padding: 0.75rem 0.75rem 0 0.75rem;
        }
        @media (max-width: 768px) {
          padding: 0.5rem 0.5rem 0 0.5rem;
          font-size: 0.8rem;
        }
        @media (max-width: 678px) {
          padding: 0.5rem 0.5rem 0 0.5rem;
        }

        > .check {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-width: 50%;
          margin: 0.25rem 0;

          > .radio {
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            border: 2px solid #cbd6e2;
            padding: 0.2rem;

            > div {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background: #999999;
            }
          }

          > .done {
            border-color: #00bda5;
            background: #00bda5;

            > div {
              background: white;
            }
          }
        }
      }

      > .button {
        width: 100%;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 500;
        background: #03b7f2;
        border-radius: 5px;
        margin: 1rem auto;

        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        @media (max-width: 768px) {
          height: 2.75rem;
          margin: 1.75rem auto;
        }

        > span {
          display: inline-block;
          position: relative;
          margin-left: 1rem;
        }
      }

      > p {
        text-align: center;
        font-size: 0.7rem;
        color: gray;
        padding: 0 1.5rem;
        margin-bottom: 1rem;

        > a {
          color: #33475b;

          &:hover {
            text-decoration: underline;
            opacity: 0.8;
          }
        }

        @media (max-width: 768px) {
          margin-bottom: 0.75rem;
          padding: 0 0.75rem;
        }
      }
    }
  }

  > .footer {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    left: 0;
  }
  .productImage {
    position: absolute;
    bottom: 0;
    left: 1rem;
    max-width: 400px;
    z-index: -1;
  }
}

@keyframes incSize {
  0% {
    margin-bottom: -50px;
    transform: scaleY(0);
  }
  100% {
    margin-bottom: 0px;
    transform: scaleY(1);
  }
}
.couponInputClass > input {
  border: 1px solid #cbd6e2;
  width: 100%;
  border-radius: 6px;
  padding: 20px;
  &:hover {
    box-shadow: 0px 0px 9px #00afef5d;
    border-color: #00bcd4;
  }
  &:focus {
    outline-width: 0;
    border-color: #00bcd4;
  }
  &:active {
    border: 1px solid #00bcd4;
  }
  &::placeholder {
    color: #bac7d6;
  }
}
.couponButtonClass {
  width: 25%;

  > button {
    cursor: pointer;
    width: 100%;
    padding: 20px;
    border-radius: 6px;
    padding: 20px 0;
    background-color: #293238;
    color: white;
    font-style: 20px;
    text-align: center;
    &:hover {
      background-color: #222629;
    }
  }
}
