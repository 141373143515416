.home {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .home-intro {
    width: 90%;
    margin: 0 auto;
    text-align: center;

    .user-info {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }

    > h2 {
      font-size: 2rem;
      color: #334760;
      margin: 0;
      margin: 1rem 0;
    }

    > p {
      font-size: 14px;
      color: #516f90;
    }
  }

  .home-content {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    h2 {
      margin: 0;
      font-weight: 600;
      color: #334760;
    }

    p {
      font-size: 14px;
      margin: 0;
      color: #516f90;
      margin-top: 10px;
    }

    .text-content {
      width: 55%;
    }
    .text-content-big {
      width: 75%;
    }
    .comp-content-big {
      width: 100%;
      padding: 4px;

      .accounts {
        display: flex;
        gap: 32px;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 1rem;
        padding: 1rem;
        .sub-cards {
          border-radius: 6px;
          box-shadow: 0px 3px 4px #f0f0f0d0;
          position: relative;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 4px 6px #d3d3d3d0;
          }
        }
      }
    }

    .image {
      width: 120px;
      height: auto;
      overflow: hidden;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .home-products {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px;
      border-bottom: 1px solid #cbd6f2;

      .products-avatar {
        display: flex;
        justify-content: space-evenly;
        .product-icon {
          height: 40px;
          width: 40px;
          margin-right: 10px;
        }
      }
    }
  }

  .links {
    text-decoration: none;
    color: inherit;
  }

  .sub-expire {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
