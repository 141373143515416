.forgot-pass-main-form {
  flex: 1;

  .forgot-pass-title {
    text-align: center;
  }
}

.bottom-policy {
  text-align: center;
  margin-top: auto;

  .aui-policy-link {
    cursor: pointer;
    color: #1592e6;
  }
}
