.help-support-container {
  padding: 0px 13px;
  margin-top: 60px;

  > div {
    border: 0.1px solid #425b7633;
    border-radius: 6px;
    box-shadow: 1px 1px 6px #425b7633;
    padding: 20px;
    width: 96%;

    > h6 {
      font-size: 22px;
      font-weight: 600;
      color: #33475b;
      margin: 0;
    }

    > .supports {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > .icon {
          width: 48px;
          height: 48px;
          background-color: #7b68ee;
        }

        > p {
          font-size: 14px;
          color: #33475b;
          margin: 0;
          margin-top: 12px;
        }
      }
    }
  }
}
