.profile-with-name {
    width: auto;
    display: flex;
    align-items: center;
    margin: 12px;

    .avatar-img {
        width: 57px;
        height: 57px;
        overflow: hidden;
        margin-right: 12px;
    }

    .noImg {
        background-color: transparent;
    }

    .round {
        border-radius: 50%;
    }
    .avatar-text {
        >h4 {
            margin: 0;
            font-weight: 500;
            color: #334760;
        } 

        >p {
            font-size: 14px;
            margin: 0;
            color: #516f90;
            margin-top: 10px;
        }
    }
}