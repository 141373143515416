.chip {
    width: fit-content;
    padding: 3px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    line-height: 145%;

    .chip-label {
        text-align: center;
        font-size: 13px;
    }
    .chip-image {
        width: 20px;
        height: 20px;
        overflow: hidden;
        margin-right: 8px;
        img {
            object-fit: cover;
        }
    }
    .right {
        margin-left: 12px;
        margin-right: 0;
    }

}

.chip-round {
    width: 80%;
    // padding: 1px 12px;
    border-radius: 25px;
    line-height: 145%;
    
    .chip-label {
        text-align: center;
    }

    .chip-image {
        width: 30px;
        height: 30px;
        padding: 4px;
        border-radius: 25px;
        overflow: hidden;
        margin-right: 12px;
        img {
            object-fit: cover;
        }
    }
    .right {
        margin-left: 12px;
        margin-right: 0;
    }

}


.chip-primary {
    border: 1px solid #B4E5DA;
    background-color: #E0F5F1;
    color: #037A5D;

    .chip-image {
        border: 1px solid #B4E5DA;
        background-color: #89b3aa;
    }
}

.chip-danger {
    border: 1px solid #FDDBB5;
    background-color: #FEF1E1;
    color: #DC461F;

    .chip-image {
        border: 1px solid #FDDBB5;
        background-color: #FEF1E1;
    }
}

.chip-classic {
    border: 1px solid #FF7A59 !important;
    background-color: #FFF1EE;
    color: #FF7A59;

    .chip-image {
        object-fit: contain;
    }
}

.chip-member {
    border: 1px solid #7986CB !important;
    background-color: #E8EAF6;
    color: #7986CB;

    .chip-image {

    }
}

.small {
    padding: 3px 5px;
}
.medium {
    padding: 3px 20px;
}
.large {
    padding: 4px 30px;
}