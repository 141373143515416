.side-bar-header {
  padding: 8px 20px;
  box-shadow: 0px 3px 6px #425b7638;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8%;

  > p {
    font-size: 1.3rem;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.8px;
  }

  > div {
    padding: 8px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.24);
    }
  }
}
