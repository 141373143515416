.tooltip {
	visibility: hidden;
	width: auto !important;
	text-align: center !important;
	padding: 1.15rem 1.5rem !important;
	border-radius: 5px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute !important;
	z-index: 100;
}

.top {
	bottom: 100%;
	left: 50%;
	margin-left: -60px;

	&::after {
		content: " ";
		position: absolute;
		top: 100%; /* At the bottom of the tooltip */
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #516f90 transparent transparent transparent;
	}
}

.bottom {
	top: 100%;
	left: 50%;
	margin-left: -60px;

	&::after {
		content: " ";
		position: absolute;
		bottom: 100%; /* At the top of the tooltip */
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent #516f90 transparent;
	}
}

.right {
	top: 5px;
	left: 105%;

	&::after {
		content: " ";
		position: absolute;
		top: 50%;
		right: 100%; /* To the left of the tooltip */
		margin-top: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent #516f90 transparent transparent;
	}
}

.left {
	top: -5px;
	right: 105%;

	&::after {
		content: " ";
		position: absolute;
		top: 50%;
		left: 100%; /* To the right of the tooltip */
		margin-top: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent transparent #516f90;
	}
}
