.custom-button {
    background-color: transparent;
    outline: none;
    box-shadow: none;
    padding: 6px 28px;
    border: 4.5px solid #334760;
    color: #334760;
    border-radius: 14px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-button-small {
    padding: 3px 16px;
}

.custom-button-medium {
    padding: 4px 20px;
}

.custom-button-large {
    padding: 6px 24px;
    border-radius: 28px;
}

.color-primary {
    color: #fff;
    background-color: #1592E6;
    border-color: #1592E6;
}
.color-primary:hover {
    background-color: #098de6;
}

.color-transparent {
    color: #586666;
    background-color: transparent;
}
.color-transparent:hover {
    color: #fff;
    background-color: #334760;
}

.color-white {
    border: 1.8px solid #fff;
    color: #fff;
    background-color: transparent;
}
.color-white:hover {
    color: #586666;
    background-color: #fff;
}

.aui-btn-round {
    border-radius: 3px !important;
}

.aui-btn-disabled {
    color: #B0C1D4;
    background-color: #EAF0F6;
    border: #EAF0F6;
}
.aui-btn-disabled:hover {
    color: #B0C1D4;
    background-color: #EAF0F6;
    border: #EAF0F6;   
}