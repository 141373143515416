.image_selector {
    margin: auto;
    max-width: 100%;
    background-color: #f0f4f9;

    & > input {
        display: none;
    }
    & > .file_label_div {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border: 1px solid #a5b6c9;
        color: #a5b6c9;
        cursor: pointer;

        & > .div {
            width: 7rem;
            font-size: 1.4rem;
            line-height: 1.25rem;
        }

        > .section {
            position: absolute;
            transform: translateX(-50%);
            bottom: 0.5rem;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-weight: 500;
            border-radius: 50%;
            background-color: #a5b6c9;
            color: white;
            transition: all 0.3s ease;

            > svg {
                path {
                    fill: white;
                }
            }

            &:hover {
                opacity: 0.9;
            }
        }
    }
}
