.user-sideBar {
    background-color: white;
    box-shadow: -4px 3px 6px #34353527;

    .drawer-top-bar {
        background-color: white;
        width: 100%;
        min-height: 64px;
        max-height: 80px;
        height: 85px;
        display: flex;
        align-items: center;
        border: 1px solid #dde2e7;
        box-shadow: 0 3px 6px #dde2e7a4;
        padding: 8px;

        @media (max-width: 992px) {
            font-size: 0.85rem;
        }
    }

    &-cross {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 2rem;
        cursor: pointer;
        color: #33475b;
        transition: all 0.3s ease;
        transform: rotateZ(45deg);
        background: #dde5ec;
        margin-top: 0.5rem;
        margin-right: 0.5rem;

        &:hover {
            background-color: #33475b;
            color: #fff;
        }
    }

    .user-info {
        margin-left: 12px;

        .usr-name {
            font-size: 1.1rem;
        }
        .usr-email,
        .setting-button {
            font-size: 0.87rem;
        }
        .setting-button {
            color: #6a78d1;
        }
    }

    .usr-sidebar-body {

        display: flex;
        height: 100%;
        flex-direction: column;

        .usr-side-bar-title {
            margin: 1rem;
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }

        .appication-note,
        .application-offer {
            padding: 1rem;
            margin-left: 1rem;
            margin-right: 1rem;
            border-radius: 2px;
            margin-bottom: 6px;
        }
        .appication-note {
            margin-top: auto;
            border: 1px solid #fddbb5;
            background-color: #fef1e1;
        }

        .application-offer {
            display: flex;
            align-items: center;
            background-color: #bbf3fd;
            border: 1px solid #a3e4fa;
        }
        
        .usr-bottom-nav {
            margin-top: auto;
            padding: 14px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0 -2px 4px #dde2e7a4;
        }
        .usr-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            > svg {
                margin-right: 6px;
            }
        }

    }
}
