.aui-checkbox-select {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #DFE3EB;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 200ms ease-in-out;
}

.aui-checkbox-selected {
    background-color: rgb(3, 143, 3);
    border: 1px solid rgb(3, 143, 3);
}


.aui-checkbox-selected svg {
    fill: #fff;
    font-size: 16px;
}

.aui-checkbox-disabled { 
    background-color: #ff5b5b80;
    border: 1px solid #cc5a5a;
}
