.aui-input {
  width: 400px;

  .aui-input-label {
    color: #33475b;
    padding-bottom: 8px;
    font-size: 1.089rem;
    font-weight: 500;
  }

  .aui-input-wrapper {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #cbd6e2;
    position: relative;

    textarea {
        border-radius: inherit;
        width: 100%;
        height: 100%;
        padding: 10px 12px;
        font-size: 1.1rem;
        outline: none;
        color: #33475b;
        border: none;

        &::placeholder,
        &:-moz-placeholder {
            font-size: 0.8rem;
            font-weight: 500;            
        }
    }

    .input-icon {
      margin: 4px;
      width: 24px;
      margin-left: 6px;
      height: 24px;
      background-color: coral;
    }

    input {
      border-radius: inherit;
      width: 100%;
      height: 100%;
      padding: 10px 12px;
      font-size: 1.1rem;
      outline: none;
      color: #33475b;
      border: none;
      

    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
    input[type=password]::-ms-reveal,
    input[type=password]::-ms-clear{
            display: none;
       }
    }
  }

  .aui-input-select {
    outline: none;
    border: 1px solid #a2c8f1;
    box-shadow: 0px 0px 4px #a2c8f1;
  }

  .input-disabled {
    input {
      background-color: #eaf0f6;
    }
  }

  .input-label-spacer {
      margin-top: 0.5rem;
  }
}

