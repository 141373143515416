.sessions {
  width: 100%;
  display: flex;

  .sessions-main {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .sessions-content {
      width: 90%;
      margin: 0 auto;
      margin-top: 1rem;
      display: flex;
      justify-content: center;

      h2 {
        margin: 0;
        font-weight: 600;
        color: #334760;
      }

      p {
        font-size: 14px;
        margin: 0;
        color: #516f90;
        margin-top: 10px;
      }
      .text-content-big {
        width: auto;
      }
      .image {
        width: 290px;
        height: 117px;
        // background-color: #00c4cb;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
      .session-ip {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 2rem;
        > h5 {
          font-weight: 400;
        }
        .session-ip-list {
            width: 90%;
            margin: 0 auto;
            display: flex;;
            flex-direction: column;
        }
      }
    }
    //   .security-contacts {
    //       width: 100%;
    //       display: flex;
    //       justify-content: space-between;
    //       align-items: center;
    //       padding: 12px 16px;
    //       border-bottom: 1px solid #CBD6F2 ;

    //       .contacts-avatar {
    //           display: flex;
    //           align-items: center;
    //           justify-content: space-evenly;

    //           >p {
    //               margin-top: 0;
    //           }
    //       }
    //   }
  }
}
