.loader {
    width: 25px;
    height: 25px;
    display: block;
    border: 3px solid #d6dae0;
    border-top-color: #8e9fb3;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}