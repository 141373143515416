.normInputBox {
  height: 4rem;
  padding: 0.6rem 1.2rem;
  color: rgba(128, 128, 128, 0.6);
  border-radius: 2.5px;
  box-shadow: none;
  border: 1px solid #cbd6e2;
  margin: 0.25rem auto;
  cursor: pointer;
  text-align: left;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 992px) {
    height: 3.6rem;
  }

  @media (max-width: 768px) {
    height: 3.3rem;
  }
  @media (max-width: 576px) {
    height: 3.1rem;
  }
  @media (max-width: 472px) {
    height: 3rem;
  }

  > .icon {
    height: 82.5%;
    padding: 0;

    @media (max-width: 992px) {
      margin: 0 0.25rem !important;
    }
    @media (max-width: 768px) {
      margin: 0 0.15rem !important;
    }

    > img {
      height: 85%;
      width: auto;
      margin-top: 3px;
    }
  }

  > .inputCont {
    flex-grow: 1;

    > input {
      box-sizing: border-box;
      border: none;
      outline: none;
      width: 100%;
      background: transparent;
      height: 100%;
    }
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #00bcd4;
    color: rgb(158, 145, 145);
    outline: none;
  }
}

.actBox {
  border-color: #00bcd4;
  color: rgb(158, 145, 145);
  outline: none;
}


.disable {
  background-color: #EAF0F6;
}