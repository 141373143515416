.tab-label-base  {
    cursor: pointer;
    padding: 0.6rem  1rem;
    border-color: transparent;
    text-align: center;
    transition: border 300ms ease-out;
    border-bottom: 1px solid #CBD6E2;
}

.tab-label-border {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}

.tab-label-active {
    border-bottom: 1px solid transparent;
    border-top: 1px solid #CBD6E2;
    border-left: 1px solid #CBD6E2;
    border-right: 1px solid #CBD6E2;
}