.side_drawer_wrapper {
    z-index: 1000000003;
    transition: all 0.3s ease-in;
    left: 0;
    top: 0;
    background-color: #343f535e;
    overflow: hidden;
    position: fixed !important;

    > .side_drawer {
        z-index: 11000;
        transition: all 0.3s ease-in;
        background-color: white;
        position: fixed;
        display: flex;
        flex-direction: column;
        max-height: 100vh;

        > .cross {
            position: absolute;
        }
    }
}
