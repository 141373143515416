.aui-page-num-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .aui-page-num {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;   
        padding: 4px;
        margin-right: 12px;
        border: 1px solid transparent;
        color: #00aeef;
        font-size: 1rem;
    }
    .aui-page-active {
        background-color: #00afef69;
        border: 1px solid #00aeef;
    }
    .aui-margin-next {
        margin-left: 12px;
    }
}