@import "../../vars.scss";

.sideBar {
  height: 100vh;
  margin: 0px;
  transition: width 0.3s ease-in-out;
  background: #ffffff;
  position: fixed;
  z-index: 1000000002;
  border-right: 1px solid #cbd6e2;

  > .topIcons {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    > .icon {
      border-radius: 2.5px;
      cursor: pointer;
      margin-top: 1.1rem;
      margin-bottom: 1.1rem;
      transition: all 0.2s ease;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      width: auto;
      position: relative;
      overflow: visible;
      text-decoration: none;

      @media (max-width: 1600px) {
        margin-top: 0.95rem;
        margin-bottom: 0.95rem;
      }
      @media (max-width: 1200px) {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
      }
      @media (max-width: 768px) {
        margin-top: 0.65rem;
        margin-bottom: 0.65rem;
      }
      @media (max-width: 576px) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      > .svg-icon {
        width: 1.5rem;
        height: 1.5rem;

        @media (max-width: 1200px) {
          width: 1.4rem;
          height: 1.4rem;
        }
        @media (max-width: 768px) {
          width: 1.3rem;
          height: 1.3rem;
        }

        svg {
          height: 100%;
          width: 100%;
          fill: #334760;
        }
      }

      > span {
        font-size: 0.89rem;
        font-weight: 500;
        color: #334760;
        transition: display 0.2s ease-in-out 0.2s;
        overflow-x: hidden;
      }

      &:hover {
        background: #dfe3eb;

        svg {
          height: 100%;
          width: 100%;
          fill: #334760;
          .st0 {
            fill: #334760;
          }
        }

        > .tooltip {
          visibility: visible;
        }
      }
    }

    > .active {
      background: #283c52;
	  span {
		  color: #fff !important;
	  }
      .svg-icon {
        svg {
          fill: #fff !important;
          .st0 {
            fill: #fff;
          }
        }
        svg {
          .profile-icon {
            stroke: #fff;
            fill: #ffff;
          }
        }
      }
    }

    > .logo {
      border-radius: 0;
      padding: 0 !important;
      background-color: rgba(255, 255, 255, 0.1);
      margin: 0;
      width: 100%;
      height: 4.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      @media (max-width: 768px) {
        height: 3.5rem;
      }

      > div {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;

        > img {
          width: 100%;
          height: 100%;
        }

        @media (max-width: 768px) {
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
  }
}

.slim {
  width: 4rem;

  @media (max-width: 1200px) {
    width: 3.5rem;
  }
  @media (max-width: 768px) {
    width: 3rem;
  }

  > .topIcons {
    > .icon {
      width: 3rem;
      height: 3rem;
      padding: 0.8rem;
      margin-left: 0.3rem;
      margin-right: 0.75rem;
      color: #63707e;
      border-radius: 50px;

      @media (max-width: 1200px) {
        width: 2.2rem;
        height: 2.2rem;
        padding: 0.45rem;
        margin-left: 0.65rem;
      }
      @media (max-width: 768px) {
        width: 2rem;
        height: 2rem;
        padding: 0.35rem;
        margin-left: 0.5rem;
      }

      > span {
        display: none;
      }

      &:hover {
        > .right {
          left: 3.5rem;

          @media (max-width: 1200px) {
            left: 3.1rem;
          }
          @media (max-width: 768px) {
            left: 2.6rem;
          }
        }
      }
    }
  }
}

.expand {
  width: 12.5rem;

  > .topIcons {
    > .icon {
      height: 2.5rem;
      padding: 0.5rem 1.25rem;
      width: 100%;
      border-radius: 0;
      border-left: 4px solid transparent;
      margin-bottom: 1.7rem;

      @media (max-width: 1200px) {
        height: 2.2rem;
        padding: 0.45rem 1.1rem;
      }
      @media (max-width: 768px) {
        height: 2rem;
        padding: 0.5rem 0.85rem;
      }

      > div {
        width: inherit;
        margin-right: 1.25rem;

        @media (max-width: 1200px) {
          margin-right: 1.25rem;
        }
        @media (max-width: 768px) {
          margin-right: 1.25rem;
        }
      }
      > span {
        display: block;
        color: #63707e;
      }

      &:hover {
        opacity: 0.85;
        background: #dfe3eb;

        > .tooltip {
          visibility: hidden;
        }
      }
    }
    > .active {
      background: #63707e;
      border-left: 4px solid #37d0ff;
      svg {
        height: 100%;
        width: 100%;
        fill: #63707e;
        .st0 {
          fill: #63707e;
        }
      }
    }

    > .logo {
      width: 90%;
      height: 4.1rem;
      margin: 0 auto;
      > div {
        height: 2.35rem;
        width: 100%;
        border-radius: 0;

        @media (max-width: 768px) {
          height: 2.15rem;
          width: 100%;
        }

        > img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.spacer {
  transition: width 0.3s ease-in-out;
}

.space-expand {
  min-width: 12.5rem;
}
.space-slim {
  width: 4rem;
  @media (max-width: 1200px) {
    width: 3.5rem;
  }
  @media (max-width: 768px) {
    width: 3rem;
  }

}
